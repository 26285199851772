import { useEffect, useRef, useState } from 'react';
import { Flex, useDisclosure, Text, Button } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom';
import {
  createContract,
  getNegotiationById,
  getNegotiationRoles,
  updateNegotiation
} from 'src/api';
import { navbarColorAtom, userAtom } from 'src/atoms';
import { LoadScreen } from 'src/components/core/LoadScreen';
import { Answer, Negotiation as INegotiation } from 'src/interfaces';
import { ContractType } from '../interfaces';
import { parseConditions } from 'src/utils/parseConditions';
import { parseAnswers } from 'src/utils/parseAnswers';
import _ from 'lodash';
import { negotiationPanelTheme } from 'src/theme/theme';
import { NavBarV2 } from 'src/v2/Navbar';
import {
  AlertModalV2,
  AlertModalV2Title,
  AlertModalV2Content,
  AlertModalV2Body,
  AlertModalV2Actions
} from 'src/v2/AlertModalV2';
import { ButtonSecondary } from 'src/v2/Button';
import { SignedIcon, ContractWarningIcon } from 'src/v2/DashboardIcons';
import { NegotiationPanel } from 'src/components/negotiation/NegotiationPanel';

export const Negotiation = () => {
  const [negotiation, setNegotiation] = useState<INegotiation | null>();
  const [, setNavbarColor] = useAtom(navbarColorAtom);
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const bg = negotiationPanelTheme.boxBackground;
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isOpenError,
    onClose: onCloseError,
    onOpen: onOpenError
  } = useDisclosure();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isGeneratingContract, setIsGeneratingContract] =
    useState<boolean>(false);

  const [params] = useSearchParams();
  const [isFirstCounter, setIsFirstCounter] = useState<boolean>(false);
  const hasCounterParam = !!params.get('first-counter');
  const roleRetrieved: any = useRef(null);
  const [firstCounterAnswers, setFirstCounterAnswers] = useState<any[]>([]);

  const [user] = useAtom(userAtom);
  const [contractStartDate, setContractStartDate] = useState<string>('');
  const [contractTitle, setContractTitle] = useState<string>('');

  //Check if this is the first response
  useEffect(() => {
    const setRole = async () => {
      if (
        hasCounterParam &&
        negotiation &&
        negotiation.receiverResponses.length === 0 &&
        roleRetrieved.current === null
      ) {
        const { data } = await getNegotiationRoles(negotiation._id);

        /**
         * Set answers to hidden questions
         */
        const answers = [
          {
            stepId: 'role',
            subStepId: 'role',
            questionId: 'role',
            fieldId: 'role',
            value: data.receiverRole
          }
        ];

        const hiddenQuestions = [];
        const conditions = (negotiation?.contractType as ContractType)
          ?.conditions;
        for (const step of (negotiation?.contractType as ContractType)?.form
          ?.steps) {
          for (const subStep of step?.subSteps) {
            const subStepCondition = conditions.subSteps?.find(
              (c: any) => c[subStep.id] !== undefined
            );
            if (
              subStepCondition != null &&
              !parseConditions(subStepCondition, parseAnswers(answers))
            ) {
              continue;
            }
            for (const question of subStep.questions) {
              const questionCondition = conditions.questions?.find(
                (c: any) => c[question.id] !== undefined
              );
              if (!question.hidden) {
                continue;
              }
              const conditionForBeingHidden =
                question?.pathWithDefaultValue?.condition;
              if (
                conditionForBeingHidden != null &&
                !parseConditions(conditionForBeingHidden, parseAnswers(answers))
              ) {
                continue;
              }
              if (
                questionCondition != null &&
                !parseConditions(questionCondition, parseAnswers(answers))
              ) {
                continue;
              }
              hiddenQuestions.push({
                ...question,
                stepId: step.id,
                subStepId: subStep.id
              });
            }
          }
        }

        for (const hiddenQuestion of hiddenQuestions) {
          const value = _.get(
            { user },
            hiddenQuestion?.pathWithDefaultValue.value
          );
          answers.push({
            stepId: hiddenQuestion.stepId,
            subStepId: hiddenQuestion.subStepId,
            fieldId: hiddenQuestion.field.id,
            questionId: hiddenQuestion.id,
            value
          });
        }

        setNegotiation({
          ...negotiation,
          latestReceiverResponses: {
            date: new Date().toString(),
            answers
          },
          receiverResponses: [
            {
              date: new Date().toString(),
              answers
            }
          ]
        });
        setIsFirstCounter(true);
        setFirstCounterAnswers(answers);
        roleRetrieved.current = data.receiverRole;
      }
    };

    setRole();
  }, [hasCounterParam, negotiation, user]);

  useEffect(() => {
    if (location.state?.showSentAlert) {
      onOpen();
    }
  }, [location.state?.showSentAlert, negotiation, onOpen]);

  useEffect(() => {
    const getNegotiation = async () => {
      const { data } = await getNegotiationById(id!);

      const { initiator, receiver, receiverRole } = data;
      const isUserInitiator = user!.email === initiator.email;

      let newReceiver: any = {};

      if (!isUserInitiator && !receiver) {
        newReceiver = {
          firstName: user!.firstName,
          lastName: user!.lastName,
          email: user!.email,
          role: receiverRole,
          profileImageUrl: ''
        };
        setNegotiation({ ...data, receiver: newReceiver });
      } else {
        setNegotiation({ ...data });
      }
    };

    try {
      setIsLoading(true);
      getNegotiation();
    } catch (error) {
      onOpenError();
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }, [id, onOpenError, setNegotiation, user]);

  useEffect(() => {
    setNavbarColor(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmitAnswers = async (answers: Answer[]) => {
    let cleanAnswers: Answer[] = answers.map(
      ({ stepId, subStepId, questionId, fieldId, value }) => ({
        stepId,
        subStepId,
        questionId,
        fieldId,
        value
      })
    ); //This is because Luke's API doesn't check that form of the answer object - need to fix that

    if (isFirstCounter) {
      cleanAnswers = [...cleanAnswers, ...firstCounterAnswers];
    }

    try {
      setIsLoading(true);
      const { data } = await updateNegotiation(negotiation!._id, {
        answers: cleanAnswers
      });
      setNegotiation(data);
      onOpen();
    } catch (err) {
      setIsLoading(false);
      onOpenError();
    } finally {
      setIsLoading(false);
    }
  };

  const handleViewContract = async () => {
    try {
      setIsGeneratingContract(true);
      const { data } = await createContract(negotiation!._id);
      setIsGeneratingContract(false);
      navigate(`/contract/${data.contract._id}`);
    } catch (err) {
      setIsGeneratingContract(false);
      onOpenError();
    } finally {
      setIsGeneratingContract(false);
    }
  };

  if (isGeneratingContract) {
    return <LoadScreen label="Generating contract..." />;
  }

  if (isLoading) {
    return <LoadScreen label="Loading..." />;
  }

  if (!negotiation) {
    return <></>;
  }

  return (
    <>
      <NavBarV2
        pb={'36px'}
        title={contractTitle}
        subtitle={`Contract started: ${contractStartDate}`}
      />
      <Flex flexDirection="column" bg="#f8f8f8">
        <Flex h="100%" alignItems="center" justifyContent="center">
          <NegotiationPanel
            maxW="1098px"
            negotiation={negotiation}
            isFirstCounter={isFirstCounter}
            onSubmitAnswers={handleSubmitAnswers}
            onViewContract={handleViewContract}
            onContractStarted={setContractStartDate}
            onTitleChange={setContractTitle}
          />
        </Flex>

        <AlertModalV2 isOpen={isOpen} onClose={onClose}>
          <AlertModalV2Body>
            <SignedIcon />
            <AlertModalV2Title>Your offer has been sent!</AlertModalV2Title>
            <AlertModalV2Content>
              We've notified the counter part
            </AlertModalV2Content>
          </AlertModalV2Body>
          <AlertModalV2Actions>
            <ButtonSecondary onClick={onClose}>Continue</ButtonSecondary>
          </AlertModalV2Actions>
        </AlertModalV2>

        <AlertModalV2 isOpen={isOpenError} onClose={onCloseError}>
          <AlertModalV2Body>
            <ContractWarningIcon />
            <AlertModalV2Title>Something went wrong!</AlertModalV2Title>
          </AlertModalV2Body>
          <AlertModalV2Actions>
            <ButtonSecondary minWidth="106px" h={'32px'} onClick={onCloseError}>
              OK
            </ButtonSecondary>
          </AlertModalV2Actions>
        </AlertModalV2>
      </Flex>
    </>
  );
};
